export const statusOptions = [
  { value: "all", label: "All Rides" },
  { value: "current-rides", label: ("Ongoing Rides") },
  { value: "completed", label: "Completed Rides" },
  { value: "cancelled", label: "Cancelled Rides" },
];

export const vehicleType = [
  { value: '', label: 'Select a Type', disabled: true},
  { value: 'HatchBack', label: 'HatchBack' },
  { value: 'Sedan', label: 'Sedan' },
  { value: 'SUV', label: 'SUV' },
]

export const orderStatusOptions = [
  { value: "all", label: "All Orders" },
  { value: "current-order", label: ("Ongoing Order") },
  { value: "completed", label: "Completed Order" },
  { value: "cancelled", label: "Cancelled Order" },
];
